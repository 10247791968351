/*DO NOT EDIT THIS FILE. IT WAS AUTOMATICALLY GENERATED.*/

@tailwind utilities;
@tailwind screens;
@tailwind components;

@tailwind base{
    html, #root {
        min-height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
    }

    .slick-slider {
        height:auto
    }
    .slick-slide {
        margin: 0 5px;
        height: inherit !important;
    }

    /* the parent */
    .slick-list {
        margin: 0 -5px;
    }

    .slick-track {
        display: flex !important;
        align-items: stretch
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        min-height: 100vh;
        width: 100vw;
        margin: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
}
@layer components {

}

.page-container {
    @apply flex flex-col min-h-screen w-screen lg:flex-row
}
.main-container {
    @apply flex flex-col items-center  p-4 lg:p-2 xl:p-8 2xl:p-16 xl:w-full xl:h-screen
}    .Heading1 {
        font-family: Poppins, sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: 0;
        text-align: left;
    }

    .Heading2 {
        font-family: Poppins, sans-serif;
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
    }

    .Heading3 {
        font-family: Poppins, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodyMedium {
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodyLight {
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodySmallMedium {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodySmallLight {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    .ShadowS {
        box-shadow: 0px 56px 70px 0px rgba(255, 255, 255, 0.14);
    }

    .ShadowM {
        box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px rgba(30, 30, 30, 0.03);
        box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px rgba(30, 30, 30, 0.04);
        box-shadow: 0px 100px 80px 0px rgba(30, 30, 30, 0.07);
    }

    .ShadowL {
        box-shadow: 0px 1.9932421445846558px 1.5945937633514404px 0px rgba(0, 0, 0, 0.02);
        box-shadow: 0px 4.528800010681152px 3.623039960861206px 0px rgba(0, 0, 0, 0.02);
        box-shadow: 0px 7.882175445556641px 6.3057403564453125px 0px rgba(0, 0, 0, 0.03);
        box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 19.31982421875px 15.455859184265137px 0px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 30.14707374572754px 24.117658615112305px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 50.048828125px 40.0390625px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    }

    .pageContainer {
        min-height: 100vh;
        width: 100vw;
        max-width: 100vw;
        background: #f3f4f6;
    }

    .mainContainer {
        display: flex;
        height: calc(100vh - 60px);
        height : -moz-calc(100vh - 60px);
        width: 100%;
    }
    .h-full-minus-navbar {
        height: calc(100vh - 60px);
        height : -moz-calc(100vh - 60px);
        min-height: calc(100vh - 60px);
        min-height : -moz-calc(100vh - 60px);
        max-height: calc(100vh - 60px);
        max-height : -moz-calc(100vh - 60px);
    }
    .bodyContainer {
        display : flex;
        height: 100%;
        width:100%;
        flex-direction: column;
        gap:32px;
        padding : 32px;
    }

